@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-toastify/dist/ReactToastify.css";
@import "~react-tippy/dist/tippy.css";

/* hebrew */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 200;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzamW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 200;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzZmW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 200;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzaGW5Kb8VZA.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzamW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzZmW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzaGW5Kb8VZA.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzamW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzZmW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzaGW5Kb8VZA.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzamW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzZmW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzaGW5Kb8VZA.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzamW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzZmW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzaGW5Kb8VZA.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzamW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzZmW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzaGW5Kb8VZA.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

/* hebrew */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 800;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzamW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 800;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzZmW5Kb8VZBHR.woff2)
		format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Assistant";
	font-style: normal;
	font-weight: 800;
	src: url(https://fonts.gstatic.com/s/assistant/v15/2sDcZGJYnIjSi6H75xkzaGW5Kb8VZA.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

::-moz-selection {
	color: #1e293b;
	background: #e2e8f0;
}

::selection {
	color: #1e293b;
	background: #e2e8f0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

body {
	margin: 0;
	font-family: "Assistant", sans-serif;
	/*-webkit-font-smoothing: antialiased;*/
	/*-moz-osx-font-smoothing: grayscale;*/
}

#__next {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.text-center {
	text-align: center;
}

select {
	background-position: left 0.5rem center !important;
}

[type="checkbox"],
[type="radio"] {
	color: #64748b;
}

[type="checkbox"]:disabled,
[type="radio"]:disabled {
	color: #c4c4c4;
}

.main-width {
	width: 100%;
	max-width: 1480px;
}

.rich-select [type="text"]:focus {
	box-shadow: none !important;
}

.text-10px {
	font-size: 10px;
}

.text-11px {
	font-size: 11px;
}

.text-12px {
	font-size: 12px;
}

.line-height-normal {
	line-height: normal;
}

.bg-transparent {
	background: transparent;
}

::-webkit-calendar-picker-indicator {
	cursor: pointer;
}

body.trigger-position-left #enable-toolbar-trigger {
	top: -100px;
}

@media (min-width: 768px) {
	body.trigger-position-left #enable-toolbar-trigger {
		top: 0px;
	}
}

.nsm7Bb-HzV7m-LgbsSe {
	background-color: transparent !important;
}

.Toastify__toast--success,
.Toastify__toast--error {
	margin-top: 10px;
}
.Toastify__toast .Toastify__close-button {
	margin: auto 0;
	padding-left: 2px;
	padding-right: 5px;
}

.animate-placeholder .animated {
	animation-duration: 3s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-name: placeholderAnimate;
	background: #f6f7f8;
	background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
	background-size: 200% 100%;
}
@keyframes placeholderAnimate {
	0% {
		background-position: -200% 0;
	}
	100% {
		background-position: 200% 0;
	}
}

/*# map */
.gm-style-iw gm-style-iw-c {
	background-color: #333 !important;
	padding-left: 7px !important;
}

.gm-style .gm-style-iw-d {
	padding: 0px !important;
	background-color: transparent !important;
	max-height: unset !important;
}
.gm-style-iw-tc::after {
	background-color: #333 !important;
}
.gm-style .gm-style-iw-c {
	padding: 0px !important;
}

.gm-style-iw-d {
	overflow: hidden !important;
	padding: 2em;
	background-color: #333 !important;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.custom-scroll::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 8px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
	background: #aaaaaa;
	border-radius: 8px;
	margin: 0 2px;
	cursor: pointer;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
	background: #7c7c7c;
}

/* Removing the default appearance */
.double-range-thumb,
.double-range-thumb::-webkit-slider-thumb {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
}

/* For Chrome browsers */
.double-range-thumb::-webkit-slider-thumb {
	background-color: #f1f5f7;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px #ced4da;
	cursor: pointer;
	height: 18px;
	width: 18px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

/* For Firefox browsers */
.double-range-thumb::-moz-range-thumb {
	background-color: #f1f5f7;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px #ced4da;
	cursor: pointer;
	height: 18px;
	width: 18px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

#customInfoWindow {
	color: #333;
	background-color: #fff;
	padding: 1em;
	border: 1px solid #333;
	border-radius: 50%;
}

/* For Firefox browsers */
.double-range-thumb::-moz-range-thumb {
	background-color: #f1f5f7;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px #ced4da;
	cursor: pointer;
	height: 18px;
	width: 18px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

#customInfoWindow {
	color: #333;
	background-color: #fff;
	padding: 1em;
	border: 1px solid #333;
	border-radius: 50%;
}

.responsive-button {
	background: white !important;
	border-color: rgb(209, 213, 219) !important;
	border-width: 1px;
	padding: 5px 0px;
	border-radius: 10px;
	text-align: center;
	color: rgb(31, 41, 55);
	font-size: 1.2rem;
	border-style: solid;
	border-color: white;
	outline: none;
	cursor: pointer;
	font-weight: normal;
}

.responsive-button2 {
	background: #414b60 !important;
	border-color: transparent !important;
	border-width: 1px;
	padding: 5px 0px;
	border-radius: 10px;
	text-align: center;
	color: white;
	font-size: 1.2rem;
	border-style: solid;
	border-color: #000000;
	outline: none;
	cursor: pointer;
	font-weight: normal;
}

.responsive-button:focus {
	background-color: #ffffff;
	outline: none;
}

@media (max-width: 600px) {
	.responsive-button {
		padding: 0.3em 2em;
		font-size: 1rem;
	}
}

.responsive-button2:focus {
	background-color: #ffffff;
	outline: none;
}

@media (max-width: 600px) {
	.responsive-button2 {
		padding: 0.3em 2em;
		font-size: 1rem;
	}
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

@keyframes slide {
	0% {
		transform: translateX(-120%);
	}
	100% {
		transform: translateX(120%);
	}
}

.animate-slide {
	animation: slide 2s linear infinite;
}

.counter-animation {
	animation: counter 0.35s ease-in-out;
	animation-fill-mode: forwards;
}

@keyframes counter {
	0% {
		transform: translateY(-40%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/* verification code input reset styles */
.vi:focus {
	outline: none;
	box-shadow: none;
	border-color: initial;
}

#g_a11y_announcement {
	right: 0;
}

/*# override map info window style */
.gm-style-iw {
	max-width: unset !important;
}

/* styles for google map infowindow 
so it doesn't cut content and have smaller X button */
.gm-style-iw {
	max-height: none !important;
}

.gm-ui-hover-effect {
	width: 32px !important;
	height: 32px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 4px !important;
}

.gm-ui-hover-effect span {
	margin: 0 !important;
	width: 100% !important;
	height: 100% !important;
}
